import React, { } from "react"
import "../../../assets/styles/_index.scss"
import Favicon from "react-favicon"
import KamanDeveloper from "../../../components/developers/kamandeveloper/KamanDeveloper";

const favicon_logo = require("../../../images/favicon.png");

const KamanPage = () => (
  <>
    <Favicon url={favicon_logo} />

    <KamanDeveloper />
  </>
)

export default KamanPage
